import React, { useState, useRef } from "react"
import { useClickAway } from "react-use"

const NavigationContainer = ({ children, className = "" }) => {
  const [isOpen, setOpen] = useState(false)
  const ref = useRef(null)
  useClickAway(ref, () => {
    setOpen(false)
  })
  let classNames = className.concat(
    " print:hidden pointer-events-none fixed top-0 right-0 sm:hidden z-20"
  )
  return (
    <div ref={ref} className={classNames}>
      <button
        className="pointer-events-auto block lg:hidden cursor-pointer ml-auto relative w-16 h-16 p-6"
        onClick={() => setOpen(!isOpen)}
      >
        {isOpen ? (
          <svg
            className="fill-current text-black"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 20 20"
          >
            <path d="M10 8.586L2.929 1.515 1.515 2.929 8.586 10l-7.071 7.071 1.414 1.414L10 11.414l7.071 7.071 1.414-1.414L11.414 10l7.071-7.071-1.414-1.414L10 8.586z" />
          </svg>
        ) : (
          <svg
            className="fill-current text-black"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 20 20"
          >
            <path d="M0 3h20v2H0V3zm0 6h20v2H0V9zm0 6h20v2H0v-2z" />
          </svg>
        )}
      </button>
      {isOpen ? children : null}
    </div>
  )
}

export default NavigationContainer
