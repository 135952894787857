import React from "react"
import { Link, useStaticQuery, graphql } from "gatsby"

const Navigation = ({ className = "" }) => {
  // const data = useStaticQuery(graphql`
  //   query {
  //     allFile(
  //       filter: {
  //         relativePath: { glob: "Portfolio/*.md" }
  //         relativeDirectory: { in: ["Portfolio"] }
  //       }
  //     ) {
  //       edges {
  //         node {
  //           relativePath
  //           base
  //           relativeDirectory
  //         }
  //       }
  //     }
  //   }
  // `)
  // data.allFile.edges
  const data = useStaticQuery(graphql`
    query {
      file(base: { eq: "Portfolio.md" }) {
        childMarkdownRemark {
          rawMarkdownBody
        }
      }
    }
  `)
  const linkRegExp = /\[(.*)\]\((.*)\)/g
  const content = data.file.childMarkdownRemark.rawMarkdownBody

  let files = []
  let matches
  while ((matches = linkRegExp.exec(content))) {
    let [, base, relativePath] = matches
    files.push({ node: { base, relativePath: `/${relativePath}` } })
  }

  return (
    <ul
      className={className.concat(
        " print:hidden -mx-2 shadow sm:shadow-none w-64 sm:w-full flex flex-col sm:flex-row sm:items-end pointer-events-auto"
      )}
    >
      {files.map(({ node: { base, relativePath } }, index) => (
        <Link
          key={index}
          to={relativePath.replace(".md", "/")}
          className="transition-border m-2 sm:my-0 p-2 sm:p-0  sm:border-b-2 border-l-2 sm:border-l-0 antialiased font-bold border-transparent hover:border-blue-300"
          activeClassName="text-gray-900 border-blue-400 hover:border-blue-400"
          partiallyActive={true}
        >
          {base.replace(".md", "")}
        </Link>
      ))}
    </ul>
  )
}

export default Navigation
