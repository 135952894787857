import React from "react"
import { useStaticQuery, graphql } from "gatsby"

const Header = ({ showNav = true }) => {
  const data = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          title
          description
        }
      }
    }
  `)
  const { title, description } = data.site.siteMetadata
  return (
    <>
      <header className={"lg:mt-0 top-0"}>
        <div className="text-inter-xl capitalize font-bold antialiased">
          {title}
        </div>
        <div className="antialiased">{description}</div>
      </header>
    </>
  )
}

export default Header
