import React from "react"
import PropTypes from "prop-types"
import ReactBodyClassname from "react-body-classname"
import Helmet from "react-helmet"

import Header from "./header"
import Footer from "./footer"
import Navigation from "./navigation"
import NavigationContainer from "./navigationcontainer"
import Breakpoints from "./breakpoints"

const Layout = ({ children, showNav = true }) => {
  return (
    <>
      <Helmet>
        <meta
          name="google-site-verification"
          content="OH8gHl-HUJasnhp1mktpxmlrL4xypF7YYJt1lNGUtqY"
        />
      </Helmet>
      <ReactBodyClassname className="font-inter" />
      <div className="max-w-2xl m-auto p-5">
        <Header showNav={showNav} />
        {process.env.NODE_ENV === "development" ? <Breakpoints /> : null}
        {showNav ? (
          <NavigationContainer>
            <Navigation className="absolute right-0 bg-gray-100" />
          </NavigationContainer>
        ) : null}
        {showNav ? <Navigation className="hidden sm:flex py-5" /> : null}
        <main className="max-w-xl">{children}</main>
      </div>
      <Footer />
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
